
.main-content {
    padding-top: 30vh;
    padding-left: 10%;
    padding-right: 10%;
}

.fade-in {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.custom-input {
    margin-top: 5%;
    border-radius: 13px; /* Скругление углов */
    padding: 10px 20px; /* Внутренние отступы */
    width: 90%; /* Полная ширина внутри колонки */
    outline: none; /* Убрать стандартный фокус браузера */
    box-shadow: 0 4px 6px rgba(6, 16, 17, 0.25); /* Тень для объемного вида */
    transition: box-shadow 0.3s ease-in-out; /* Плавный переход тени */
}

.custom-input:focus {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5); /* Усиленная тень при фокусе */
}

.custom-row {
    padding: 2%; /* Уменьшаем внутренние отступы */
}

/* Кастомные радио-кнопки */
.custom-radio-button {
    margin: 10px 0;
    display: block;
}

.radio-input {
    margin-top: 5%;
    display: none;
}

.radio-label {
    margin-top: 5%;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.radio-label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    background-color: white;
}

.radio-input:checked + .radio-label:before {
    background-color: black;
    border-color: black;
}

.radio-label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    transition: all 0.3s ease;
    opacity: 0;
}

.radio-input:checked + .radio-label:after {
    opacity: 1;
    background: white;
}



.custom-row {
    margin-left: 2.5%;
    margin-right: 2.5%;
    padding: 2%;
}
