.step {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background-color: #bbb;
    color: white;
    text-align: center;
    font-size: 14px;
    position: relative; /* Для позиционирования линии */
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    background-color: black;
}
.stepsProgressBar {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.line {
    height: 2px;
    background-color: #ccc;
    flex-grow: 1; /* Занимает доступное пространство между шагами */
    position: relative; /* Для позиционирования частичной линии */
}

.line.active {
    background-color: black; /* Зелёный цвет для активных линий */
}

.line.partial {
    background-color: black; /* Зелёный цвет для частичной линии */
}

.partialFill {
    height: 2px;
    background-color: black;
    width: 75%; /* 75% ширины линии */
    position: absolute;
    margin-right: 25%; /* Сокращает линию на 25% от ширины родительской линии */
}
